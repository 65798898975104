.paginatorComponent .item {
  align-items: center;
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.paginatorComponent .disabled-page {
  color: #808e9b;
}

.paginatorComponent .active {
  border: solid 1px orange;
  border-radius: 40px;
  /* color: #808e9b; */
  color: orange;
}

.paginatorComponent .next {
  /* border-left: solid 1px #808e9b; */
  font-size: 14px;
  height: 60px;
  position: absolute;
  right: 0;
  /* width: 150px; */
}

.paginatorComponent .next > a {
  color: orange;
}

.paginatorComponent .pagination {
  align-items: center;
  /* background-color: #0fbcf9; */
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 100%;
}

.paginatorComponent .pagination-page {
  font-weight: 700;
}

.paginatorComponent .previous {
  /* border-right: solid 1px #808e9b; */
  font-size: 14px;
  height: 60px;
  left: 0;
  position: absolute;
  /* width: 150px; */
}
.paginatorComponent .previous > a {
  color: orange;
}